import type { GetAppManifestFn, TFunction, EditorReadyFn } from '@wix/yoshi-flow-editor';
import { WIDGET_IDS } from './consts';
import { configureCommonGfpp } from './editor/appManifest';
import { EditorType, type ExportsFn } from '@wix/platform-editor-sdk';
import { createMenuPage } from './utils/createPage';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

const TOKEN = '';
let t: TFunction;

export const editorReady: EditorReadyFn = async (editorSDK, appDefId, platform, flowAPI) => {
  const isMenusDashboardAppInstalled = await editorSDK.application.isApplicationInstalled(TOKEN, {
    appDefinitionId: APP_DEF_IDS.menus,
  });

  if (!isMenusDashboardAppInstalled) {
    await editorSDK.document.application.add(TOKEN, {
      appDefinitionId: APP_DEF_IDS.menus,
    });
  }
};

export const getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK, contextParams, flowAPI) => {
  t = flowAPI.translations.t as TFunction;

  const manifest = appManifestBuilder.configureWidget(WIDGET_IDS.menus, (widgetBuilder) => {
    configureCommonGfpp(widgetBuilder, t, editorSDK, flowAPI);
  });
  return manifest.build();
};

export const exports: ExportsFn = (editorSDK, context) => {
  const editorType = context.origin.type;
  const isResponsive = editorType === EditorType.Responsive;

  return {
    public: {},
    private: {
      createMenuPage: async () => {
        await createMenuPage({ editorSDK, t, tpaAppId: context.initialAppData.applicationId, isResponsive });
      },
    },
  };
};
