import { restaurantsEditorPanelLoad } from '@wix/bi-logger-restaurants-data/v2';
import type { EditorScriptFlowAPI, FlowEditorSDK, TFunction, WidgetBuilder } from '@wix/yoshi-flow-editor';
import { BiReferral } from 'root/components/Menus/Settings/consts';
import { MENU_WIDGET_NAME, MenuGFPPButtons, MenuPanelName, PAGE_NAME, WIDGET_IDS } from 'root/consts';
import { openManageMenuDashboardPanel } from 'root/utils/panels';

export const configureCommonGfpp = (
  widgetBuilder: WidgetBuilder,
  t: TFunction,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('app.gfpp.manageMenus'),
      onClick: async () => {
        const metaSiteId = await editorSDK.document.info.getMetaSiteId('');
        flowAPI.bi?.report(
          restaurantsEditorPanelLoad({
            origin: MenuGFPPButtons.Manage,
            panelName: MenuPanelName.Manage,
            product: PAGE_NAME,
            sitePageName: PAGE_NAME,
            widgetId: WIDGET_IDS.menus,
            widgetName: MENU_WIDGET_NAME,
            msid: metaSiteId,
            isOoi: true,
          })
        );
        openManageMenuDashboardPanel(editorSDK, BiReferral.ManageMenusGfpp);
      },
    })
    .set('mainAction2', { behavior: 'DEFAULT1' });
};
