export enum TabIds {
  Manage = 'Manage',
  Menus = 'Menus',
  Layout = 'Layout',
  Display = 'Display',
  Design = 'Design',
}
export enum TabActions {
  Manage = 'Manage Menus',
  Add = 'Add Menu Page',
  ResetLayout = 'Reset Layout',
}
export enum BiReferral {
  ManageMenusButton = 'Ooi_Manage_Menus_button',
  ManageMenusGfpp = 'Ooi_Manage_Menus_GFPP',
  GoToDashboard = 'Ooi_Go_To_Dashboard',
}
