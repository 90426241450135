import type { ComponentStructure } from 'root/clientTypes';

export const createSectionDefinition = (components: ComponentStructure[]): ComponentStructure => {
  return {
    type: 'Container',
    components,
    componentType: 'responsive.components.Section',
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: 500,
        },
      },
      itemLayout: {
        id: '',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
      type: 'SingleLayoutData',
    },
  };
};
